import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
            title="Digital Hub Denmark Newsletter"
            titleTemplate="Digital Hub Denmark Newsletter"
        />
    );
};

export default SEO;
