import React, { useCallback, useEffect, useRef, useState } from "react"
import * as THREE from "three"
import rubber_diffuse_256 from "./mat/rubber_diffuse_256.jpg"
import rubber_roughness_256 from "./mat/rubber_roughness_256.jpg"
import reflection_map from "./reflection_map.png"

export class Vector {
  constructor(x = 0, y = 0, z = 0) {
    this.x = x
    this.y = y
    this.z = z
  }
}

export class Particle {
  constructor() {
    this.restPosition = new Vector() // Initial position particles was born with
    this.radius = 1
    this.moveFraction = 0.001 // Move speed
  }
}

export function fit(x, a1, a2, b1, b2) {
  return b1 + ((x - a1) * (b2 - b1)) / (a2 - a1)
}

export function randFit(seed, min, max) {
  return fit(Math.random(seed), 0, 1, min, max)
}

const isMedium = () => {
  return window.matchMedia("(min-width: 1024px)").matches
}

const Particles = () => {
  const [ref, setRef] = useState(null)
  const canvasRef = useRef()

  let diffuseMap,
    envMap,
    roughnessMap,
    mouse,
    scene,
    camera,
    renderer,
    height,
    width

  let canvasWidth = 0

  useEffect(() => {
    if (ref) setup()
  }, [ref])

  const onRefLoaded = useCallback(node => {
    setRef(node)
  }, [])

  const setup = () => {
    const image1 = document.createElement("img")
    diffuseMap = new THREE.Texture(image1)
    image1.onload = () => (diffuseMap.needsUpdate = true)
    image1.src = rubber_diffuse_256

    const image2 = document.createElement("img")
    envMap = new THREE.Texture(image2)
    image2.onload = () => (envMap.needsUpdate = true)
    image2.src = reflection_map
    envMap.mapping = THREE.SphericalReflectionMapping

    const image3 = document.createElement("img")
    roughnessMap = new THREE.Texture(image3)
    image3.onload = () => (roughnessMap.needsUpdate = true)
    image3.src = rubber_roughness_256

    setCanvasSize(true)

    setTimeout(() => init(), 0)
  }

  const setCanvasSize = (updateWidth = false) => {
    width = Math.floor(ref.getBoundingClientRect().width)
    height = Math.floor(ref.getBoundingClientRect().height)
    if (updateWidth) canvasWidth = width

    return {
      w: width,
      h: height,
    }
  }

  const onResize = () => {
    const { w, h } = setCanvasSize()
    if (w !== canvasWidth) {
      canvasWidth = w
      scene.userData.renderer.setSize(w, h)

      camera.aspect = w / h
      camera.updateProjectionMatrix()
      renderer.setSize(w, h)
    }
  }

  const onMouseMove = event => {
    event.preventDefault()
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  const init = () => {
    mouse = new THREE.Vector2()

    const particlesObj = {
      particle_count: 10,
      min_radius: 10,
      max_radius: 80,
      color: "#FFE964",
    }

    const { particle_count, min_radius, max_radius, color } = particlesObj

    const spread_x = 1.0
    const spread_y = 0.65
    const min_move_fraction = 0.0001
    const max_move_fraction = 0.05
    const travel_area_scale = 0.25

    let particleAttribs = {
      count: particle_count,
      radius: {
        min: parseFloat(isMedium() ? min_radius : Math.ceil(min_radius / 2)),
        max: parseFloat(isMedium() ? max_radius : Math.ceil(max_radius / 2)),
      },
      spread: { x: spread_x, y: spread_y },
      moveFraction: { min: min_move_fraction, max: max_move_fraction },
      color: color,
      travelAreaScale: travel_area_scale,
    }

    // Scene, camera and render
    scene = new THREE.Scene()

    camera = new THREE.OrthographicCamera(
      -width,
      width,
      height,
      -height,
      -10000,
      100000
    )
    camera.position.z = 10000

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.setSize(width, height, false)

    ref.appendChild(renderer.domElement)

    // Lights
    const lightAmbient = new THREE.AmbientLight("#fff", 1.6)
    scene.add(lightAmbient)

    const lightSpot = new THREE.SpotLight("#fff", 1.5)
    lightSpot.position.y = 100000
    lightSpot.position.x = 100000
    lightSpot.position.z = 40000
    lightSpot.angle = 1.05
    lightSpot.decacy = 2
    lightSpot.penumbra = 1
    lightSpot.shadow.camera.near = 10
    lightSpot.shadow.camera.far = 1000
    lightSpot.shadow.camera.fov = 30
    lightSpot.shadow.radius = 88
    scene.add(lightSpot)

    // Materials
    const material = new THREE.MeshPhysicalMaterial({
      color: color,
      roughness: 1,
    })
    material.envMap = envMap
    material.map = diffuseMap
    roughnessMap.magFilter = THREE.NearestFilter
    material.roughnessMap = roughnessMap
    //material.bumpMap = this.roughnessMap
    material.bumpScale = 0.03

    // Geometry
    const particles = []

    // Create instance sphere and mesh (Instead of creating a mesh for every particle we create one mesh for all particles)
    const sphereGeo = new THREE.SphereBufferGeometry(1, 64, 64)
    const sphereGeoInstance = new THREE.InstancedMesh(
      sphereGeo,
      material,
      particle_count
    )

    // Create transform to control the particles matrix
    const transform = new THREE.Object3D()

    let i = 0
    // Setup particles
    for (i = 0; i < particle_count; i++) {
      const restPosition = new Vector()

      // Randomize x and y position
      const spreadXToCanvasSpace = particleAttribs.spread.x * width
      restPosition.x = randFit(
        i + 3243,
        -spreadXToCanvasSpace,
        spreadXToCanvasSpace
      )

      const spreadYToCanvasSpace = particleAttribs.spread.y * height
      restPosition.y = randFit(
        i + 7125,
        -spreadYToCanvasSpace,
        spreadYToCanvasSpace
      )

      // Move new particle it's diameter behind the previous, to prevent intersection
      restPosition.z = i * (particleAttribs.radius.max * 2)

      // Randomize move fraction and radius
      const moveFraction = randFit(
        i + 2532,
        particleAttribs.moveFraction.min,
        particleAttribs.moveFraction.max
      )
      const radius = randFit(
        i + 9532,
        particleAttribs.radius.min,
        particleAttribs.radius.max
      )

      // Set the particle instance matrix
      transform.position.set(restPosition.x, restPosition.y, restPosition.z)
      transform.scale.set(radius, radius, radius)
      transform.updateMatrix()

      sphereGeoInstance.setMatrixAt(i, transform.matrix)
      sphereGeoInstance.instanceMatrix.needsUpdate = true

      const particle = new Particle()
      particle.restPosition = restPosition
      particle.moveFraction = moveFraction
      particle.radius = radius

      particles.push(particle)
    }

    scene.add(sphereGeoInstance)

    // Set scene user data
    scene.userData.renderer = renderer
    scene.userData.camera = camera
    scene.userData.element = ref
    scene.userData.particles = particles
    scene.userData.travelAreaScale = travel_area_scale
    scene.userData.mesh = sphereGeoInstance

    animate()

    window.addEventListener("resize", () => onResize())
    document.body.addEventListener("mousemove", e => onMouseMove(e), false)

    setTimeout(() => {
      ref.style.opacity = "1"
    }, 50)
  }

  const animate = () => {
    const {
      element,
      travelAreaScale,
      particles,
      mesh,
      renderer,
      camera,
    } = scene.userData

    const { y, height } = element.getBoundingClientRect()

    // Normalize target position
    const targetYPositionNormalized = fit(
      y,
      window.innerHeight,
      -height,
      -travelAreaScale,
      travelAreaScale
    )
    const targetYPosition = fit(
      targetYPositionNormalized,
      -1,
      1,
      height,
      -height
    )

    // Translate particles
    const { length } = particles
    let i = 0
    for (i = 0; i < length; i++) {
      const particle = particles[i]

      const { radius, restPosition, moveFraction } = particle

      // Get matrix and position from matrix
      const transform = new THREE.Object3D()

      // Get vector3 of matrix
      const particlePosition = new THREE.Vector3(0, 1, 0)
      const matrix = new THREE.Matrix4()
      mesh.getMatrixAt(i, matrix)
      particlePosition.setFromMatrixPosition(matrix)

      // By normalized mouse x pos
      const targetXPosition = mouse.x * 100
      particlePosition.x +=
        (targetXPosition + restPosition.x - particlePosition.x) * moveFraction

      // By scroll y pos
      particlePosition.y +=
        (targetYPosition + restPosition.y - particlePosition.y) * moveFraction

      transform.position.set(
        particlePosition.x,
        particlePosition.y,
        particlePosition.z
      )

      transform.scale.set(radius, radius, radius)
      transform.updateMatrix()
      mesh.setMatrixAt(i, transform.matrix)
      mesh.instanceMatrix.needsUpdate = true
    }

    renderer.render(scene, camera)

    requestAnimationFrame(animate)
  }

  return (
    <div className="particles">
      <div className="particle-systems" ref={onRefLoaded} />
    </div>
  )
}

export default Particles
