import React, { useEffect, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router'
import tw, { css } from 'twin.macro';
import CTAButton from '../components/CTAButton'
import Particles from '../components/Particles/Particles'
import SEO from '../components/SEO'
import { validateEmail } from '../utils'

const contentStyles = css`
  margin-top: -70px;

  @media only screen and (max-width: 1024px) {
    margin-top: 20px;
  }
  ${tw`lg:(pl-16 px-0 w-3/5 self-end absolute top-0 right-0)`}
  li {
    font-size: 1.125rem;
  }
`

const titleStyles = css`
  line-height: 1;
  ${tw`block towards text-4xl lg:(w-1/2 text-5xl)`}
`

const Title = ({ children }) => <h1 css={titleStyles}>{children}</h1>

const checkedStyles = css`
  &:after {
    content: "";
    position: absolute;
    width: 18px;
    height: 19px;
    background-color: #5ec2bb;
    top: 2px;
    left: 2px;
  }
`

const errorStyles = css`
  color: red;

  a {
    text-decoration: underline;
  }
`

export default () => {
  const { href } = useLocation()
  const { allDatoCmsNewsletter } = useStaticQuery(
    graphql`
      query MyQuery {
        allDatoCmsNewsletter {
          edges {
            node {
              hashtagSlug
              title
              bodyTextNode {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    `
  );
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [fieldsValid, setFieldsValid] = useState();
  const [iConsent, setIConsent] = useState(false);
  const [formStatus, setFormStatus] = useState();
  const [error, setError] = useState(false);
  const [signupFrom, setSignupFrom] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    const arr = [...allDatoCmsNewsletter.edges]
    let obj = {}
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i].node
      obj = {
        ...obj, [item.hashtagSlug]: {
          title: item.title,
          bodyText: item.bodyTextNode.childMarkdownRemark.html
        }
      }
    }
    setContent(obj)
  }, []);

  useEffect(() => {
    let counter = 0;
    if (firstName.length > 0) counter += 1;
    if (lastName.length > 0) counter += 1;
    if (jobTitle.length > 0) counter += 1;
    if (validateEmail(email)) counter += 1;
    if (iConsent) counter += 1;
    setFieldsValid(counter);
    setError(false)
  }, [firstName, lastName, jobTitle, email, iConsent]);

  const onSubmit = async () => {
    let fields = {
      FIRSTNAME: firstName,
      LASTNAME: lastName,
      JOBTITLE: jobTitle,
    }
    if (signupFrom) fields = { ...fields, SIGNUPFROM: signupFrom }
    const response = await addToMailchimp(email, fields)

    if (response.result === "success") setFormStatus(response.result)
    if (response.result === "error") setError(response)
  }

  useEffect(() => {
    const acceptedSlugs = allDatoCmsNewsletter.edges.map(item => item.node.hashtagSlug)
    const slug = href?.split('#')[1];
    if (acceptedSlugs.includes(slug)) {
      setSignupFrom(slug)
      document.body.classList.add(slug)
    }
  }, []);

  if (content) {
    return (
      <>
        <SEO />
        <div id="newsletter" tw="max-w-8xl w-full pb-20 px-5 pt-28 lg:(px-24 pt-44)">
          <Particles />
          <div tw="relative z-10 flex flex-col">
          {formStatus === 'success' &&
            <div tw="w-1/2 mx-auto">
              <h1 tw="text-xl text-green font-bold mb-4">Welcome! 🎉</h1>
              <p tw="mb-2">
                We look forward to having you on board & deliver relevant updates to your inbox! 📬
              </p>
            </div>
          }
          {!formStatus &&
            <>
              <Title>{content[signupFrom?.toLowerCase() || "default"].title}</Title>
              <div dangerouslySetInnerHTML={{ __html: content[signupFrom?.toLowerCase() || "default"].bodyText}} css={contentStyles} />
              <div tw="flex flex-wrap justify-between w-full">
                {error && <p css={errorStyles} dangerouslySetInnerHTML={{ __html: error.msg }} />}
                <div tw="w-full flex flex-col lg:(flex-row w-3/5)">
                  <Input id="mce-FNAME" value={firstName} label="First Name" onChange={setFirstName} />
                  <Input id="mce-MMERGE2" value={lastName} label="Last Name" onChange={setLastName} pl10 />
                </div>
                <Input id="mce-MMERGE3" value={jobTitle} label="Job Title" onChange={setJobTitle} pl10 w25 />
                <Input id="mce-EMAIL" value={email} label="Email" onChange={setEmail} mt10 w35 />
                <div tw="mt-8 w-full flex items-start flex-col-reverse text-xxs lg:(pl-10 mt-16 justify-end items-end w-2/5)">
                  <label htmlFor="consent" tw="flex items-center justify-start my-4 w-full">
                    <div
                      id="consent"
                      onClick={() => setIConsent((val) => !val)}
                      css={[tw`focus:border-green outline-none relative inline-block text-transparent cursor-pointer border border-white border-solid h-6 w-6 mr-5`, iConsent && checkedStyles]}
                      role="button"
                      aria-label="consent"
                      tabIndex="0"
                      onKeyPress={() => setIConsent((val) => !val)}
                    />
                    <span>Yes, I hereby give my consent to receive the newsletter by e-mail.</span>
                  </label>
                  <div>At Digital Hub Denmark we would like to use your name and e-mail so that we can send you our newsletter.. <br/><br/>You can withdraw your consent at any time, either by clicking on “Unsubscribe” at the bottom of a newsletter or by sending us an email at <a href="mailto: info@digitalhubdenmark.dk" tw="underline">info@digitalhubdenmark.dk</a></div>
                  <CTAButton
                    onClick={() => onSubmit()}
                    disabled={fieldsValid !== 5}
                    label={formStatus === 'sending' ? 'Sending...' : 'Sign up'}
                  />
                </div>
              </div>
            </>
          }
          </div>
        </div>
      </>
    );
  }
  return null
};

const Input = ({
  id, label, onChange, type, value, pl10, w25, mt10, w35
}) => {
  const [inFocus, setInFocus] = useState();
  const completed = value?.length > 0 && !inFocus;

  return (
    <label
      htmlFor="mce-MMERGE3"
      css={[tw`w-full`, pl10 && tw`lg:pl-10`, w25 && tw`lg:w-2/5`, mt10 && tw`lg:mt-10`, w35 && tw`lg:w-3/5`]}
    >
      <span
        css={[
          tw`inline-flex pointer-events-none transition-transform origin-left transform`,
          inFocus || completed ? tw`translate-y-2 scale-75` : tw`translate-y-9 scale-100`,
          completed && tw`text-white-25`,
          inFocus && tw`text-green`
        ]}
      >
        {label}
      </span>
      <input
        css={[tw`w-full h-11 outline-none bg-transparent border-b border-solid`, inFocus && tw`border-green`, completed && tw`border-white-25`]}
        id={id}
        name={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type || 'text'}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus()}
      />
    </label>
  );
};
