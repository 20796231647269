// import mailchimp from '@mailchimp/mailchimp_marketing'

// mailchimp.setConfig({
//     apiKey: process.env.GATSBY_MC_API_KEY,
//     server: process.env.GATSBY_MC_SERVER,
// });

// export const callPing = async () => {
//     const response = await mailchimp.ping.get();
//     console.log(response);
// }

export const callPing = async () => {
    const reponse = await fetch(`https://${process.env.GATSBY_MC_SERVER}.api.mailchimp.com/3.0/ping`, {

    })
    console.log(reponse)
}

export const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};