import React, { useEffect } from 'react';
import tw, { css } from 'twin.macro';
import VanillaTilt from 'vanilla-tilt';

const ArrowIcon = () => (
  <svg
    width="20"
    height="10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    tw="inline-block"
  >
    <path
      d="M1 5h18M16 1l3.5 4L16 9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CTAButton = ({ onClick, disabled, external, href, label, blueOutline }) => {
  const initTilt = () => {
    const tiltOptions = {
      max: 12,
      speed: 1800,
      reverse: false,
      glare: true,
      'max-glare': 0.5,
    };

    const elements = document.querySelectorAll('.tilt');
    if (elements && elements.length > 0) {
      VanillaTilt.init(elements, tiltOptions);
    }
  };

  useEffect(() => {
    initTilt();
  }, []);

  const Tag = external ? 'a' : 'div';
  return (
    <Tag
      tw="w-full mb-5 text-base"
      className={`cta-button ${blueOutline && 'blue-on-white'}`}
      href={href || undefined}
      target={external ? '_blank' : null}
      rel={external ? 'noopener noreferrer' : null}
    >
      <button
        tw="py-4 px-5 focus:border-green focus:outline-none"
        className="tilt-button tilt"
        type="button"
        onClick={onClick || undefined}
        disabled={disabled}
        style={{ opacity: disabled ? 0.2 : 1 }}
        tabIndex="0"
      >
        <span>{label}</span>
        <ArrowIcon />
      </button>
    </Tag>
  );
};

export default CTAButton;
